<template>
  <el-row :class="router ? 'smallCode' : ''">
    <el-col :span="router ? 8 : 16">
      <el-input
        placeholder="验证码"
        size="small"
        :clearable="true"
        v-model="codeValue"
      >
        <img
          src="@/assets/images/password_icon.png"
          class="password-icon"
          slot="prefix"
          alt=""
          v-if="isIcon"
        />
      </el-input>
    </el-col>
    <el-col
      :span="8"
      class="get-code"
      :class="router ? (time == 60 ? 'active-button' : '') : ''"
      ><el-button size="small" @click="getCode">{{
        time != 60
          ? time + "秒后重新获取"
          : router
          ? "发送验证码"
          : "获取验证码"
      }}</el-button></el-col
    >
  </el-row>
</template>

<script>
import { getCode, codeLogin } from "@/api/user";
export default {
  props: {
    isIcon: {
      type: Boolean,
      default: true,
    },
    phone: {
      type: String,
    },
  },
  data() {
    return {
      time: 60,
      timer: null,
      codeValue: "",
      flag: true,
      router: false,
    };
  },
  watch: {
    codeValue(val) {
      this.$emit("getCode", val);
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  mounted() {
    this.router =
      this.$route.name == "create" || this.$route.name == "resetPassword"
        ? true
        : false;
  },
  methods: {
    getCode() {
      //获取验证码
      if (this.flag && this.time == 60) {
        this.flag = false;
        this.$emit("codeVerify", () => {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "获取中",
            background: "rgba(0, 0, 0, 0.7)",
          });
          //手机号验证成功
          getCode({
            phone: this.phone,
          })
            .then((res) => {
              loading.close();
              if (res && res.status == 200) {
                if (res) {
                  this.$message({
                    message: "验证码发送成功，请留意短信",
                    type: "success",
                  });
                  this.countDown();
                  localStorage.setItem("code", Base64.encode(res.data.code));
                  localStorage.setItem("phone", Base64.encode(res.data.phone));
                  localStorage.setItem("time", Base64.encode(res.data.time));
                  this._clearCode();
                }
              }
            })
            .catch((error) => {
              this.flag = true;
              loading.close();
            });
          setTimeout(() => {
            loading.close();
          }, 5000);
        });
      }
    },
    _clearCode() {
      setTimeout(() => {
        localStorage.removeItem("code");
      }, 300000);
    },
    // 倒计时
    countDown() {
      this.timer = setInterval(() => {
        if (this.time == 0) {
          clearInterval(this.timer);
          this.time = 60;
          this.flag = true;

          return;
        }
        this.time--;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.get-code {
  text-align: right;
  .el-button {
    width: 95%;
    padding: 0;
    text-align: center;
    margin-left: 5%;
    border-radius: 2px;
    height: 40px;
    color: rgba(156, 156, 156, 0.8);
  }
}
.password-icon {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  padding-left: 5px;
}
.el-input {
  img {
    margin-top: -3px;
  }
}
.smallCode {
  .el-input {
    text-align: center;
    padding: 0;
    height: 32px;
    /deep/.el-input__inner {
      padding: 0;
      text-align: center;
      height: 32px !important;
    }
  }
  .el-button {
    width: auto;
    padding: 0 11px;
    height: 32px;
    box-sizing: border-box;
  }
  .active-button {
    .el-button {
      background: #00a0e9;
      color: #fff;
    }
  }
}
</style>