<template>
  <div>
    <el-input
      maxlength="11"
      placeholder="手机号"
      size="small"
      :clearable="true"
      v-model="phoneValue"
      :disabled="disable"
    >
      <img
        src="@/assets/images/user_icon.png"
        class="password-icon"
        slot="prefix"
        alt=""
        v-if="!isMore"
      />
      <template v-else slot="prepend">+86 <i class="el-icon-arrow-down"></i></template>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    isMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneValue: "",
    };
  },
  watch: {
    phoneValue(val) {
      if (!this.disable) {
        this.$emit("getPhone", val);
      }
    },
  },
  mounted() {
    if (this.$route.name == "create"||this.$route.name == "resetPassword") {
      this.phoneValue = localStorage.getItem('step1')
      if (this.phoneValue) {
        this.$emit("getPhone", this.phoneValue);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.password-icon {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  padding-left: 5px;
}
.el-input {
  img {
    margin-top: -3px;
  }
  /deep/.el-input-group__prepend{
    height: 39px;
  }
}
</style>